<template>
  <div class="allcontens">
    <div class="content">
      <div class="account_content">
        <img src="@assets/agreement/correct.svg" alt="完成代扣" class="account_img">
        <span class="account_span">您的信息已认证</span>
        <!-- 2022.3.23 暂时不跳转到契约锁签约 -->
        <!-- <span @click="sign" class="go-sign">请前往签约</span> -->
        <span class="tips"></span>
      </div>
    </div>
  </div>
</template>
<script>
  import { deployContract } from '@/api/sign'
  import storage from '@/utils/storage'
  export default {
    data() {
      return { }
    },
    created() {
      this.sign()
    },
    methods: {
      async sign() {
        const res = await deployContract({ insuranceNo: storage.getItem(`insuranceNo`) })
        if (res.success) {
          // 2022.3.23 暂时不跳转到契约锁签约
          // window.location.href = res.content.signUrl
        } else {
          this.$toast(res.respMag)
        }
      },
    }
  }
</script>
<style scoped>
  .account_span {
    font-size: 0.5rem;
    font-weight: 700;
    color: black;
    line-height: 0.8rem;
    margin-top: 0.6rem;
  }

  .account_img {
    width: 2rem;
    height: 2rem;
  }

  .account_content {
    width: 100%;
    height: 8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  hr {
    width: 100%;
    margin-top: 1.5rem;
  }

  .go-sign {
    font-size: 18px;
    color: #1989fa;
    margin-top: 10px;
  }
</style>
